export default {
  container: {
    position: 'relative',
    maxWidth: ['90%', '250px'],
    height: 'auto',
    zIndex: 0,
  },
  cardContent: (isHovered, secondaryStyle) => ({
    fontFamily: 'light',
    position: 'sticky',
    backgroundColor: secondaryStyle ? 'primary' : 'secondary',
    width: '100%',
    height: '195px',
    zIndex: 1,
    opacity: 0,
    mt: '-30px',
    transform: 'translateY(-195px)',
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    padding: ['35px 22px 10px 22px', '22px 22px 10px 22px'],
    boxShadow:
      'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',

    ...(isHovered && {
      transform: 'translateY(0)',
      opacity: 1,
    }),
    webkitTransition: '.3s linear',
    mozTransition: '.3s linear',
    transition: '.3s linear',

    a: {
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: secondaryStyle ? 'secondary' : 'primary',
      borderRadius: '50px',
      width: 'fit-content',
      padding: '2px 1.5rem',
      color: 'tertiary',
      fontSize: '16px',
      height: '30px',
    },
  }),
}
