import Box from '../Commons/Box'
import Text from '../Commons/Text'
import styles from './styles'
import Image from '../Commons/Image'
import { useState } from 'react'
import Link from 'next/link'

const InfoCard = ({ isMobile, defaultOpen, secondaryStyle, ...props }) => {
  const { image, description, action, linkText } = props
  const [isHovered, setIsHovered] = useState(defaultOpen ? true : isMobile)

  const handleMouseEnter = () => {
    defaultOpen ? null : setIsHovered(true)
  }

  const handleMouseLeave = () => {
    defaultOpen ? null : setIsHovered(false)
  }

  return (
    <Box
      sx={styles.container}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box styles={{ position: 'relative', zIndex: 6 }}>
        <Image src={image} alt="info_card_image" />
      </Box>
      <Box sx={styles.cardContent(isHovered, secondaryStyle)}>
        <Text textStyle="infoCardDescription">{description}</Text>
        <Link href={action}>{linkText}</Link>
      </Box>
    </Box>
  )
}

export default InfoCard
