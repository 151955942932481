import React from 'react'

import WithMainLayout from '../components/hocs'
import HomeContent from '../components/HomeContent'

import utils from '../utils'

const Home = ({ layout, utmParams, isMobile }) => {
  return (
    <HomeContent
      {...layout?.home?.component}
      isMobile={isMobile}
      utmParams={utmParams}
    />
  )
}
export default WithMainLayout(Home)

export async function getServerSideProps(ctx) {
  const geoInfo = await utils.getGeoIpData(ctx.req)

  return {
    props: {
      ip: geoInfo.ip,
      isoCode: geoInfo.isoCode,
      isManchester: geoInfo.isManchester,
      geoip: geoInfo.geoip,
    },
  }
}
