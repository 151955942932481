/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Box from '../Commons/Box'
import Image from '../Commons/Image'
import Text from '../Commons/Text'
import { PartnersList } from '../PartnersList'
import InfoCard from '../InfoCard'
import styles from './styles'

const HomeContent = ({ isMobile, ...props }) => {
  const {
    batIcon,
    serpentineIcon,
    dropsIcon,
    ballIcon,
    partnersList,
    cardList,
    officialTeamPartnerText,
    homeMiddleSectionText,
    homeYellowText,
  } = props

  return (
    <Box sx={styles.container}>
      <Box sx={styles.topPageSection}>
        <Image src={batIcon.src} alt={batIcon.alt} imageStyle="batIcon" />
        <PartnersList partnersList={partnersList} isHomePage />
        <Image
          src={serpentineIcon.src}
          alt={serpentineIcon.alt}
          imageStyle="serpentineIcon"
        />
      </Box>

      {!isMobile && (
        <Text textStyle="homeBasicText">{officialTeamPartnerText}</Text>
      )}

      <Box sx={styles.textBox}>
        <Text textStyle="homeBasicText">{homeMiddleSectionText}</Text>
        <Text textStyle="homeYellowText">{homeYellowText}</Text>
      </Box>

      <Box sx={styles.bottomPageSection}>
        {!isMobile && (
          <Image src={ballIcon.src} alt={ballIcon.alt} imageStyle="ballIcon" />
        )}
        <Box sx={styles.cardsBox}>
          {cardList &&
            cardList.map((card, index) => {
              return index === 0 ? (
                <InfoCard isMobile={isMobile} secondaryStyle {...card} />
              ) : (
                <InfoCard isMobile={isMobile} {...card} />
              )
            })}
        </Box>
        {!isMobile && (
          <Image
            src={dropsIcon.src}
            alt={dropsIcon.alt}
            imageStyle="dropsIcon"
          />
        )}
      </Box>
    </Box>
  )
}

export default HomeContent
