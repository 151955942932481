export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    flexGrow: '1',
    backgroundImage: 'url("/images/home_background_image.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    paddingBottom: ['16rem', 0],
  },
  topPageSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'baseline',
  },
  textBox: {
    display: 'flex',
    flexDirection: ['column', 'row'],
    marginTop: '8px',
    textAlign: 'center',
  },
  bottomPageSection: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '3rem',
    marginTop: '1rem',
  },
  cardsBox: {
    alignItems: ['center', 'flex-start'],
    display: 'flex',
    flexDirection: ['column', 'row'],
    gap: '2rem',
    minHeight: ['auto', '200px'],
  },
}
